import React, { useLayoutEffect, useState } from 'react';
import { BigNumber } from 'bignumber.js';
import { Box, Typography } from '@mui/material';
import { Trans } from '@lingui/macro';
import { EndTriggerType, RangeConfigType } from '@yldr/contract-helpers';

import { displayRangeValue } from '../../../utils/displayRangeValue';
import { useModalContext } from '../../../hooks/useModal';
import { ToggleButtonGroup } from '../../../ui-kit/ToggleButtonGroup';
import { useAppDataContext } from '../../../hooks/app-data-provider/useAppDataProvider';
import { findTokenInReserves } from '../../../utils/findTokenInReserves';
import { useCurrency } from '../../../components-yldr/LiquidityChartRangeInput/hooks/useCurrency';
import { PercentageStepCounter } from '../../../ui-kit/PercentageStepCounter';
import { convertTickDiffToPercent } from '../../../utils/convertTickDiffToPercent';
import { convertPercentToTickDiff } from '../../../utils/convertPercentToTickDiff';
import { convertToSqrtPriceX96 } from '../../../utils/convertToSqrtPriceX96';
import { calculatePositionRange } from '../../../utils/calculatePositionRange';
import { ToggleButton } from '../../primitives/ToggleButton/ToggleButton';
import { ScrollModalContent, ScrollModalFooter, ScrollModalHeader } from '../../primitives/ScrollModal';
import { DetailsNumberLine, TxModalDetails } from '../FlowCommons/TxModalDetails';
import { TxModalTitleModern } from '../FlowCommons/TxModalTitle';
import { PositionModalWrapperProps } from '../FlowCommons/PositionModalWrapper';
import { TxSuccessView } from '../FlowCommons/Success';
import { SLIPPAGE_WARNING } from '../constants';
import { AutoRebalanceEndOptions } from './AutoRebalanceEndOptions';
import { AutoRebalanceOutOfRange } from './AutoRebalanceOutOfRange';
import { AutoRebalancePrice } from './AutoRebalancePrice';
import { AutoRebalancePercent } from './AutoRebalancePercent';
import { AutoRebalanceActions, DeleveragePositionActionsProps } from './AutoRebalanceActions';
import { EGasFeeCeilingType } from './types';
import { detectChangedValues } from './helpers/detectChangedValues';
import { decompileRebalanceData } from './helpers/decompileRebalanceData';
import { getDiffDaysToTimestamp } from './helpers/getDiffDaysToTimestamp';
import { AutoRebalanceGasFeeCeiling } from './AutoRebalanceGasFeeCeiling';
import {
  LOWER_PERCENT_MAX,
  LOWER_PERCENT_MIN,
  PROTOCOL_FEE,
  REBALANCE_TRIGGER_LIST,
  UPPER_PERCENT_MAX,
  UPPER_PERCENT_MIN,
} from './constants';
import { AlertBanner } from '../../../ui-kit/AlertBanner';

export const AutoRebalanceModalContent = React.memo(
  ({ enrichedPosition, configuredAutomationsData, positionAddress, isWrongNetwork }: PositionModalWrapperProps) => {
    const [rangeConfigType, setRangeConfigType] = useState(RangeConfigType.RANGE);
    const [gasFeeCeilingType, setGasFeeCeilingType] = useState(EGasFeeCeilingType.percent);
    const [gasFeeCeilingValue, setGasFeeCeilingValue] = useState('');
    const [autoRebalanceInitialized, setAutoRebalanceInitialized] = useState(false);
    const [endConfigEnabled, setEndConfigEnabled] = useState(false);
    const [endTriggerType, setEndTriggerType] = useState(EndTriggerType.COUNT);
    const [endTriggerValue, setEndTriggerValue] = useState('0');
    const [triggerLower, setTriggerLower] = useState(enrichedPosition.tickLower);
    const [triggerUpper, setTriggerUpper] = useState(enrichedPosition.tickUpper);
    const [lowerPercent, setLowerPercent] = useState('');
    const [upperPercent, setUpperPercent] = useState('');
    const [isSorted, setSorted] = useState(true);
    const { mainTxState, txError, setTxError, gasLimit } = useModalContext();
    const { reserves } = useAppDataContext();

    const tokenA = findTokenInReserves(reserves, enrichedPosition.token0.underlyingAsset);
    const tokenB = findTokenInReserves(reserves, enrichedPosition.token1.underlyingAsset);

    const baseCurrency = useCurrency(isSorted ? tokenA : tokenB);
    const quoteCurrency = useCurrency(isSorted ? tokenB : tokenA);

    const displayCurrentPrice = isSorted ? enrichedPosition.poolPrice : enrichedPosition.invertedPoolPrice;

    const triggerLowerAllowable = triggerLower >= enrichedPosition.tickCurrent
      ? enrichedPosition.tickCurrent - 1
      : triggerLower

    const triggerUpperAllowable = triggerUpper <= enrichedPosition.tickCurrent
      ? enrichedPosition.tickCurrent + 1
      : triggerUpper

    const newTicksDown = Math.abs(convertPercentToTickDiff(Number(lowerPercent)));
    const newTicksUp = Math.abs(convertPercentToTickDiff(Number(upperPercent)));

    const triggerLowerPrice = calculatePositionRange(
      triggerLower,
      enrichedPosition.token0.decimals,
      enrichedPosition.token1.decimals,
    );
    const triggerUpperPrice = calculatePositionRange(
      triggerUpper,
      enrichedPosition.token0.decimals,
      enrichedPosition.token1.decimals,
    );

    const hasValueChanges = detectChangedValues(
      configuredAutomationsData?.rebalances[0],
      enrichedPosition,
      {
        triggerLower: triggerLowerAllowable,
        triggerUpper: triggerUpperAllowable,
        rangeConfigType,
        gasFeeCeilingType,
        gasFeeCeilingValue,
        endTriggerType,
        endTriggerValue,
        lowerPercent,
        upperPercent,
      },
    );

    const autoRebalanceActionsProps: DeleveragePositionActionsProps = {
      marketId: enrichedPosition.marketId,
      initialized: autoRebalanceInitialized,
      recurring: {
        rangeConfig: {
          rangeConfigType: rangeConfigType,
          ticksDown: rangeConfigType === RangeConfigType.TICKS
            ? enrichedPosition.tickCurrent - triggerLower
            : 0,
          ticksUp: rangeConfigType === RangeConfigType.TICKS
            ? triggerUpper - enrichedPosition.tickCurrent
            : 0,
          sqrtPriceX96Down: rangeConfigType === RangeConfigType.PRICE
            ? convertToSqrtPriceX96(
              enrichedPosition.poolPrice.minus(triggerLowerPrice?.toFixed() || 0).abs(),
              enrichedPosition.token0.decimals,
              enrichedPosition.token1.decimals,
            ).toFixed() : '0',
          sqrtPriceX96Up: rangeConfigType === RangeConfigType.PRICE
            ? convertToSqrtPriceX96(
              BigNumber(triggerUpperPrice?.toFixed() || 0).minus(enrichedPosition.poolPrice).abs(),
              enrichedPosition.token0.decimals,
              enrichedPosition.token1.decimals,
            ).toFixed() : '0',
        },
        endConfig: endConfigEnabled ? {
          triggerType: endTriggerType,
          count: endTriggerType === EndTriggerType.COUNT
            ? parseInt(endTriggerValue)
            : 0,
          timestamp: endTriggerType === EndTriggerType.TIMESTAMP
            ? Math.round(Date.now() / 1000) + Math.round(parseInt(endTriggerValue) * 24 * 60 * 60)
            : 0,
        } : {
          triggerType: EndTriggerType.TIMESTAMP,
          count: 0,
          timestamp: Math.round(Date.now() / 1000) + Math.round(100 * 365 * 24 * 60 * 60)
        },
        active: true,
      },
      gasFeeConfig: {
        maxUsd: gasFeeCeilingType === EGasFeeCeilingType.usd
          ? Number(gasFeeCeilingValue)
          : 0,
        maxPositionPercent: gasFeeCeilingType === EGasFeeCeilingType.percent
          ? BigNumber(gasFeeCeilingValue).multipliedBy(100).decimalPlaces(0).toNumber()
          : 0,
      },
      triggerLower: triggerLowerAllowable,
      triggerUpper: triggerUpperAllowable,
      newTicksDown,
      newTicksUp,
      positionAddress,
      isWrongNetwork,
      hasValueChanges,
    };

    console.log('autoRebalanceActionsProps', autoRebalanceActionsProps);

    const handleOnCurrencyToggle = () => setSorted((prevState) => !prevState);

    useLayoutEffect(() => {
      const rebalanceDataSaved = decompileRebalanceData(configuredAutomationsData?.rebalances[0]);
      const lowerPercentSaved = convertTickDiffToPercent(-rebalanceDataSaved.newTicksDown).toFixed();
      const upperPercentSaved = convertTickDiffToPercent(rebalanceDataSaved.newTicksUp).toFixed();
      const gasFeeCeilingTypeSaved = rebalanceDataSaved.gasFeeConfig.maxUsd
        ? EGasFeeCeilingType.usd
        : EGasFeeCeilingType.percent;
      if (rebalanceDataSaved.initialized) {
        setAutoRebalanceInitialized(rebalanceDataSaved.initialized);
        setRangeConfigType(rebalanceDataSaved.recurring.rangeConfig.rangeConfigType);
        setLowerPercent(lowerPercentSaved);
        setUpperPercent(upperPercentSaved);
        setEndConfigEnabled(rebalanceDataSaved.recurring.active);
        setEndTriggerType(rebalanceDataSaved.recurring.endConfig.triggerType);
        setEndTriggerValue(
          rebalanceDataSaved.recurring.endConfig.triggerType === EndTriggerType.COUNT
            ? rebalanceDataSaved.recurring.endConfig.count.toString()
            : getDiffDaysToTimestamp(rebalanceDataSaved.recurring.endConfig.timestamp).toString()
        );
        setGasFeeCeilingType(gasFeeCeilingTypeSaved);
        setGasFeeCeilingValue(
          gasFeeCeilingTypeSaved === EGasFeeCeilingType.usd
            ? rebalanceDataSaved.gasFeeConfig.maxUsd.toString()
            : rebalanceDataSaved.gasFeeConfig.maxPositionPercent.toString()
        );
      } else {
        const lowerPrice = calculatePositionRange(
          enrichedPosition.tickLower,
          enrichedPosition.token0?.decimals,
          enrichedPosition.token1?.decimals,
        );
        const upperPrice = calculatePositionRange(
          enrichedPosition.tickUpper,
          enrichedPosition.token0?.decimals,
          enrichedPosition.token1?.decimals,
        );
        const lowerPercentDefault = displayCurrentPrice
          .minus(lowerPrice?.toFixed() || 0)
          .div(enrichedPosition.poolPrice.div(100))
          .multipliedBy(-1)
          .decimalPlaces(0);
        const upperPercentDefault = displayCurrentPrice
          .multipliedBy(-1)
          .plus(upperPrice?.toFixed() || 0)
          .div(enrichedPosition.poolPrice.div(100))
          .decimalPlaces(0);
        setLowerPercent(
          lowerPercentDefault.isLessThan(LOWER_PERCENT_MAX)
            ? lowerPercentDefault.toFixed()
            : LOWER_PERCENT_MAX.toString()
        );
        setUpperPercent(
          upperPercentDefault.isGreaterThan(UPPER_PERCENT_MIN)
            ? upperPercentDefault.toFixed()
            : UPPER_PERCENT_MIN.toString()
        );
      }
    }, []);

    if (mainTxState.success) {
      return (
        <ScrollModalContent>
          <TxSuccessView
            action={<Trans>successfully enabled</Trans>}
            amount={mainTxState.value}
            prefix="$"
          />
        </ScrollModalContent>
      );
    }

    return (
      <>
        <ScrollModalHeader withDivider={true}>
          <TxModalTitleModern
            title={autoRebalanceInitialized ? 'Disable auto-rebalance' : 'Enable auto-rebalance'}
            marketName={enrichedPosition?.marketName}
            tokenA={enrichedPosition?.token0}
            tokenB={enrichedPosition?.token1}
            fee={enrichedPosition?.fee}
          />
        </ScrollModalHeader>

        <ScrollModalContent sx={{ display: 'flex', flexDirection: 'column', gap: 5, pt: 5 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography variant="secondary14" color="text.tertiary">
              Rebalance position when
            </Typography>
            <ToggleButtonGroup
              color="primary"
              exclusive
              value={rangeConfigType}
              onChange={(_event, value) => setRangeConfigType(value)}
              sx={{ width: '100%' }}
            >
              {REBALANCE_TRIGGER_LIST.map((item) => (
                <ToggleButton
                  key={item.value}
                  value={item.value}
                  size="small"
                  sx={{
                    flex: '1 1 auto',
                    fontSize: '14px',
                  }}
                >
                  {item.label}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
            <Typography variant="secondary13" color="text.secondary">Current Price</Typography>
            <Box sx={{
              display: 'flex',
              alignItems: 'end',
              gap: 1.5,
              lineHeight: 0,
              ['>p']: {
                lineHeight: '100%',
              }
            }}>
              <Typography variant="secondary16">{displayRangeValue(displayCurrentPrice)}</Typography>
              <Typography variant="secondary14" color="text.tertiary">
                {baseCurrency?.symbol} per {quoteCurrency?.symbol}
              </Typography>
            </Box>
          </Box>

          {rangeConfigType === RangeConfigType.RANGE && (
            <AutoRebalanceOutOfRange
              enrichedPosition={enrichedPosition}
              baseCurrency={baseCurrency}
              quoteCurrency={quoteCurrency}
              symbolTokenA={enrichedPosition.token0.symbol}
              symbolTokenB={enrichedPosition.token1.symbol}
              setTriggerLower={setTriggerLower}
              setTriggerUpper={setTriggerUpper}
              onCurrencyToggle={handleOnCurrencyToggle}
              isSorted={isSorted}
            />
          )}

          {rangeConfigType === RangeConfigType.PRICE && (
            <AutoRebalancePrice
              enrichedPosition={enrichedPosition}
              configuredAutomationsData={configuredAutomationsData}
              symbolTokenA={enrichedPosition.token0.symbol}
              symbolTokenB={enrichedPosition.token1.symbol}
              setTriggerLower={setTriggerLower}
              setTriggerUpper={setTriggerUpper}
              onCurrencyToggle={handleOnCurrencyToggle}
              isSorted={isSorted}
            />
          )}

          {rangeConfigType === RangeConfigType.TICKS && (
            <AutoRebalancePercent
              enrichedPosition={enrichedPosition}
              configuredAutomationsData={configuredAutomationsData}
              symbolTokenA={enrichedPosition.token0.symbol}
              symbolTokenB={enrichedPosition.token1.symbol}
              setTriggerLower={setTriggerLower}
              setTriggerUpper={setTriggerUpper}
              onCurrencyToggle={handleOnCurrencyToggle}
              isSorted={isSorted}
            />
          )}

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography variant="secondary14" color="text.tertiary">
              Then set new price range
            </Typography>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <PercentageStepCounter
                label="Min price"
                description="Below future price"
                value={lowerPercent}
                minValue={LOWER_PERCENT_MIN}
                maxValue={LOWER_PERCENT_MAX}
                onChange={setLowerPercent}
              />
              <PercentageStepCounter
                label="Max price"
                description="Above future price"
                value={upperPercent}
                minValue={UPPER_PERCENT_MIN}
                maxValue={UPPER_PERCENT_MAX}
                onChange={setUpperPercent}
              />
            </Box>
          </Box>

          <AutoRebalanceGasFeeCeiling
            gasFeeCeilingType={gasFeeCeilingType}
            setGasFeeCeilingType={setGasFeeCeilingType}
            value={gasFeeCeilingValue}
            setValue={setGasFeeCeilingValue}
            marketReferenceCurrencyValue={enrichedPosition.marketReferenceCurrencyValue}
          />

          <AutoRebalanceEndOptions
            endConfigEnabled={endConfigEnabled}
            setEndConfigEnabled={setEndConfigEnabled}
            endTriggerType={endTriggerType}
            setEndTriggerType={setEndTriggerType}
            value={endTriggerValue}
            setValue={setEndTriggerValue}
          />

          <TxModalDetails
            description={SLIPPAGE_WARNING}
            gasLimit={gasLimit}
            skipLoad={true}
            sx={{ mt: 0 }}
          >
            <DetailsNumberLine
              description="Protocol fee"
              captionColor="text.secondary"
              value={PROTOCOL_FEE}
              percent={true}
            />
          </TxModalDetails>

          {txError && (
            <AlertBanner variant="error" txError={txError} onClose={() => setTxError(undefined)} />
          )}
        </ScrollModalContent>

        <ScrollModalFooter>
          <AutoRebalanceActions {...autoRebalanceActionsProps} />
        </ScrollModalFooter>
      </>
    );
  }
);
