import React, { useEffect } from 'react';
import RangeIcon from '/public/range.svg';
import { Box, Typography } from '@mui/material';
import { Currency } from '@uniswap/sdk-core';
import { displayRangeValue } from '../../../utils/displayRangeValue';
import { CurrencyToggler } from '../../../components-yldr/components/CurrencyToggler';
import { IEnrichedUniswapPosition } from '../../../types/uniswapTokens';
import { getTickToPrice } from '../../../components-yldr/LiquidityChartRangeInput/utils/getTickToPrice';

interface IPriceWidgetProps {
  label: string;
  value?: string;
  symbolTokenA: string;
  symbolTokenB: string;
}

const PriceWidget = ({ label, value, symbolTokenA, symbolTokenB }: IPriceWidgetProps) => (
  <Box sx={{
    flex: 1,
    px: 4,
    py: 3,
    borderRadius: '8px',
    border: '1px solid rgba(255, 255, 255, 0.06)',
    background: '#1F1F23',
  }}>
    <Typography variant="secondary13" color="text.secondary">{label}</Typography>
    <Typography variant="main15" sx={{ mt: 1, mb: 0.5 }}>{displayRangeValue(value)}</Typography>
    <Typography variant="main12" color="text.tertiary">
      {symbolTokenA} per {symbolTokenB}
    </Typography>
  </Box>
)

export interface IAutoRebalanceOutOfRangeProps {
  enrichedPosition: IEnrichedUniswapPosition;
  baseCurrency: Currency | undefined;
  quoteCurrency: Currency | undefined;
  symbolTokenA: string;
  symbolTokenB: string;
  onCurrencyToggle: () => void;
  isSorted: boolean;
  setTriggerLower: React.Dispatch<React.SetStateAction<number>>;
  setTriggerUpper: React.Dispatch<React.SetStateAction<number>>;
}

export const AutoRebalanceOutOfRange = ({
  enrichedPosition,
  baseCurrency,
  quoteCurrency,
  symbolTokenA,
  symbolTokenB,
  setTriggerLower,
  setTriggerUpper,
  onCurrencyToggle,
  isSorted,
}: IAutoRebalanceOutOfRangeProps) => {

  const lowerPrice = getTickToPrice(
    baseCurrency?.wrapped,
    quoteCurrency?.wrapped,
    isSorted ? enrichedPosition.tickLower : enrichedPosition.tickUpper,
  );

  const upperPrice = getTickToPrice(
    baseCurrency?.wrapped,
    quoteCurrency?.wrapped,
    isSorted ? enrichedPosition.tickUpper : enrichedPosition.tickLower,
  );

  useEffect(() => {
    setTriggerLower(enrichedPosition.tickLower);
    setTriggerUpper(enrichedPosition.tickUpper);
  }, [enrichedPosition.tickLower, enrichedPosition.tickUpper]);

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 4 }}>
        <Typography variant="secondary14" color="text.tertiary">
          If position is out of range
        </Typography>
        <CurrencyToggler
          baseCurrencySymbol={isSorted ? symbolTokenA : symbolTokenB}
          quoteCurrencySymbol={isSorted ? symbolTokenB : symbolTokenA}
          isSorted={isSorted}
          onCurrencyToggle={onCurrencyToggle}
          sx={{ flex: 'unset', width: 'unset' }}
        />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 3 }}>
        <PriceWidget
          label="Min price"
          value={lowerPrice?.toFixed(12)}
          symbolTokenA={isSorted ? symbolTokenA : symbolTokenB}
          symbolTokenB={isSorted ? symbolTokenB : symbolTokenA}
        />
        <RangeIcon/>
        <PriceWidget
          label="Max price"
          value={upperPrice?.toFixed(12)}
          symbolTokenA={isSorted ? symbolTokenA : symbolTokenB}
          symbolTokenB={isSorted ? symbolTokenB : symbolTokenA}
        />
      </Box>
    </Box>
  );
};
