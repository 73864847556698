import { Currency, Token } from '@uniswap/sdk-core';

import { useWeb3Context } from '../../../libs/hooks/useWeb3Context';
import { ComputedReserveData } from '../../../hooks/app-data-provider/useAppDataProvider';

export function useCurrency(token?: ComputedReserveData): Currency | undefined {
  const { chainId } = useWeb3Context();
  return token
    ? new Token(
      chainId, token.underlyingAsset, token.decimals, token.symbol, token.name
    )
    : undefined;
}


