import React, { useEffect, useRef } from 'react';
import { Box, Switch, Typography } from '@mui/material';
import { EndTriggerType } from '@yldr/contract-helpers';
import { ToggleButtonGroup } from '../../../ui-kit/ToggleButtonGroup';
import { NumericInput } from '../../../ui-kit/NumericInput';
import { ToggleButton } from '../../primitives/ToggleButton/ToggleButton';
import { DEFAULT_REBALANCE_COUNTS, DEFAULT_REBALANCE_DAYS, REBALANCE_MIN_VALUE } from './constants';

export interface IAutoRebalanceEndOptionsProps {
  endConfigEnabled: boolean;
  setEndConfigEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  endTriggerType: EndTriggerType;
  setEndTriggerType: React.Dispatch<React.SetStateAction<EndTriggerType>>;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
}

export const AutoRebalanceEndOptions = ({
  endConfigEnabled,
  setEndConfigEnabled,
  endTriggerType,
  setEndTriggerType,
  value,
  setValue,
}: IAutoRebalanceEndOptionsProps) => {
  const rebalanceCountRef = useRef(DEFAULT_REBALANCE_COUNTS.toString());
  const rebalanceDaysRef = useRef(DEFAULT_REBALANCE_DAYS.toString());

  const handleOnTriggerSwitch = (newTriggerType: EndTriggerType) => {
    if (newTriggerType === null) return;
    setEndTriggerType(newTriggerType);
    setValue(
      newTriggerType === EndTriggerType.COUNT
        ? rebalanceCountRef.current
        : rebalanceDaysRef.current
    );
  }

  const handleOnActivate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEndConfigEnabled(event.target.checked);
    setValue(
      endTriggerType === EndTriggerType.COUNT
        ? rebalanceCountRef.current
        : rebalanceDaysRef.current
    );
  }

  const handleOnchange = (value: string) => {
    setValue(value);
  }

  useEffect(() => {
    if (!value || !endConfigEnabled) return;
    if(endTriggerType === EndTriggerType.COUNT) {
      rebalanceCountRef.current = value;
    }
    if(endTriggerType === EndTriggerType.TIMESTAMP) {
      rebalanceDaysRef.current = value;
    }
  }, [value]);

  return (
    <Box>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 4,
        pt: 2.5,
        pb: 1.5,
      }}>
        <Typography variant="secondary14">
          End auto-rebalancing
        </Typography>
        <Switch
          checked={endConfigEnabled}
          onChange={handleOnActivate}
        />
      </Box>
      {endConfigEnabled && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 3 }}>
          <Typography variant="secondary14" color="text.tertiary">
            End auto-rebalancing after
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <ToggleButtonGroup
              color="primary"
              exclusive
              value={endTriggerType}
              onChange={(_event, value) => handleOnTriggerSwitch(value)}
              sx={{ flex: 'unset', width: 'unset' }}
            >
              <ToggleButton
                size="small"
                value={EndTriggerType.COUNT}
                sx={{ width: '50%', fontSize: '14px' }}
              >
                Count
              </ToggleButton>
              <ToggleButton
                size="small"
                value={EndTriggerType.TIMESTAMP}
                sx={{ width: '50%', fontSize: '14px' }}
              >
                Days
              </ToggleButton>
            </ToggleButtonGroup>
            <NumericInput
              minValue={REBALANCE_MIN_VALUE}
              value={value}
              onChange={handleOnchange}
              sx={{
                borderRadius: '8px',
                border: '1px solid rgba(255, 255, 255, 0.06)',
                backgroundColor: '#2A2A32',
                py: 1,
                px: 3,
              }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};
