import React, { useEffect } from 'react';
import { Box, BoxProps } from '@mui/material';
import { EndConfigStruct, RangeConfigStruct } from '@yldr/contract-helpers';
import { TransactionResponse } from '@ethersproject/providers';
import { queryClient } from '../../../../pages/_app.page';
import { QueryKeys } from '../../../ui-config/queries';
import { useModalContext } from '../../../hooks/useModal';
import { useWeb3Context } from '../../../libs/hooks/useWeb3Context';
import { useRootStore } from '../../../store/root';
import { getErrorTextFromError, TxAction } from '../../../ui-config/errorMapping';
import { TxPositionActionsWrapper } from '../TxPositionActionsWrapper';

export interface DeleveragePositionActionsProps extends BoxProps {
  marketId?: number;
  positionAddress?: string;
  triggerLower: number;
  triggerUpper: number;
  newTicksDown: number;
  newTicksUp: number;
  recurring: {
    rangeConfig: RangeConfigStruct;
    endConfig: EndConfigStruct;
    active: boolean;
  };
  gasFeeConfig: {
    maxUsd: number;
    maxPositionPercent: number;
  };
  isWrongNetwork: boolean;
  initialized?: boolean;
  hasValueChanges: boolean;
}

export const AutoRebalanceActions = React.memo(
  ({
     marketId,
     positionAddress,
     triggerLower,
     triggerUpper,
     newTicksDown,
     newTicksUp,
     gasFeeConfig,
     recurring,
     isWrongNetwork,
     initialized,
     hasValueChanges,
  }: DeleveragePositionActionsProps) => {
    const { mainTxState, setMainTxState, setTxError, setGasLimit } = useModalContext();
    const { sendTx } = useWeb3Context();
    const chainId = useRootStore((store) => store.currentChainId);

    const [
      setupAutoRebalance,
      cancelAutoRebalance,
      estimateGasLimit,
    ] = useRootStore((state) => [
      state.setupAutoRebalance,
      state.cancelAutoRebalance,
      state.estimateGasLimit,
    ]);

    const setupAction = async () => {
      if (
        !marketId ||
        !positionAddress ||
        !triggerLower ||
        !triggerUpper ||
        !newTicksDown ||
        !newTicksUp ||
        !gasFeeConfig ||
        mainTxState.success
      ) return;

      try {
        window.gtag('event', 'leverage_modal_setup_auto_rebalance');

        setTxError(undefined);
        setMainTxState({ ...mainTxState, loading: true, value: 'setup' });

        const setupAutoRebalanceTxData = setupAutoRebalance({
          marketId,
          positionAddress,
          triggerLower,
          triggerUpper,
          newTicksDown,
          newTicksUp,
          recurring,
          gasFeeConfig,
        });

        const response: TransactionResponse = await sendTx(setupAutoRebalanceTxData);
        await queryClient.invalidateQueries({
          queryKey: [QueryKeys.YLDR_LEVERAGE_AUTOMATIONS, chainId, marketId, [positionAddress]],
        });
        await response.wait(1);

        await queryClient.invalidateQueries({
          queryKey: [QueryKeys.YLDR_LEVERAGE_AUTOMATIONS, chainId, marketId, [positionAddress]],
        });

        setMainTxState({
          txHash: response.hash,
          loading: false,
          success: true,
        });

      } catch (error) {
        const parsedError = getErrorTextFromError(error, TxAction.GAS_ESTIMATION, false);
        setTxError(parsedError);
        setMainTxState({
          txHash: undefined,
          loading: false,
        });
      }
    };

    const cancelAction = async () => {
      if (!marketId || !positionAddress || mainTxState.success) return;
      try {
        window.gtag('event', 'leverage_modal_cancel_auto_rebalance');

        setTxError(undefined);
        setMainTxState({ ...mainTxState, loading: true, value: 'cancel' });

        const cancelAutoRebalanceTxData = cancelAutoRebalance(marketId, positionAddress);

        const response: TransactionResponse = await sendTx(cancelAutoRebalanceTxData);
        await queryClient.invalidateQueries({
          queryKey: [QueryKeys.YLDR_LEVERAGE_AUTOMATIONS, chainId, marketId, [positionAddress]],
        });
        await response.wait(1);

        await queryClient.invalidateQueries({
          queryKey: [QueryKeys.YLDR_LEVERAGE_AUTOMATIONS, chainId, marketId, [positionAddress]],
        });

        setMainTxState({
          txHash: response.hash,
          loading: false,
          success: true,
        });

      } catch (error) {
        const parsedError = getErrorTextFromError(error, TxAction.GAS_ESTIMATION, false);
        setTxError(parsedError);
        setMainTxState({
          txHash: undefined,
          loading: false,
        });
      }
    };

    //Update gas estimation
    useEffect(() => {
      if (
        !marketId ||
        !positionAddress ||
        !triggerLower ||
        !triggerUpper ||
        !newTicksDown ||
        !newTicksUp ||
        !gasFeeConfig ||
        mainTxState.success
      ) return;

      const calculateGasLimit = async () => {
        const autoRebalanceTx = setupAutoRebalance({
          marketId,
          positionAddress,
          triggerLower,
          triggerUpper,
          newTicksDown,
          newTicksUp,
          gasFeeConfig,
          recurring,
        });
        const autoCompoundTxData = await estimateGasLimit(autoRebalanceTx);
        setGasLimit(autoCompoundTxData.gasLimit?.toString() || '0');
      }

      const timerId = setTimeout(calculateGasLimit, 500);
      const intervalId = setInterval(calculateGasLimit, 15000);
      return () => {
        clearInterval(intervalId);
        clearTimeout(timerId);
      }
    }, [
      triggerLower,
      triggerUpper,
      newTicksDown,
      newTicksUp,
      gasFeeConfig.maxUsd,
      gasFeeConfig.maxPositionPercent,
      mainTxState.success,
    ]);

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <TxPositionActionsWrapper
          mainTxState={mainTxState}
          disabledLoading={mainTxState.value === 'cancel'}
          isWrongNetwork={isWrongNetwork}
          requiresAmount
          preparingTransactions={false}
          actionText={initialized ? 'Apply changes' : 'Enable auto-rebalance'}
          actionInProgressText={initialized ? 'Applying changes' : 'Enabling auto-rebalance'}
          handleAction={setupAction}
          variantPositive={true}
          disabled={!hasValueChanges && initialized}
        />
        {initialized && (
          <TxPositionActionsWrapper
            mainTxState={mainTxState}
            disabledLoading={mainTxState.value === 'setup'}
            isWrongNetwork={isWrongNetwork}
            requiresAmount
            preparingTransactions={false}
            actionText="Disable auto-rebalance"
            actionInProgressText="Disabling auto-rebalance"
            handleAction={cancelAction}
            variantPositive={false}
            sx={{ mt: 0 }}
          />
        )}
      </Box>
    );
  }
);
